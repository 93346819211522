//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { apiErrorHandler, isMobile } from '@/utils/helper';

export default {
	name: 'Authenticated',
	data() {
		return {
			idleTimeout: null,
			width: null,
		};
	},
	computed: {
		...mapGetters({
			busy: 'app/busy',
			showToast: 'toast/show',
			autoDismiss: 'toast/autoDismiss',
			toastMessage: 'toast/message',
			toastTheme: 'toast/theme',
			toastIsLoading: 'toast/loading',
			showHelp: 'app/showHelp',
		}),
	},
	watch: {
		$route() {
			const routeContainer = this.$el.querySelector('#route-container');

			if (routeContainer) {
				routeContainer.scrollTo({
					top: 0,
					behavior: 'smooth',
				});
			}

			this.closeAllMenus();
			if (isMobile()) this.closeHelpCenter(false);
		},
	},
	mounted() {
		this.initIdleTimer();
		this.isAppBusy(false);
	},
	beforeDestroy() {
		this.clearIdleTimer();
	},
	methods: {
		...mapActions({
			isAppBusy: 'app/busy',
			closeAllMenus: 'app/closeAllMenus',
			closeHelpCenter: 'app/showHelp',
		}),
		initIdleTimer() {
			document.addEventListener('mousemove', this.resetIdleTimer, false);
			document.addEventListener('mousedown', this.resetIdleTimer, false);
			document.addEventListener('keypress', this.resetIdleTimer, false);
			document.addEventListener('touchmove', this.resetIdleTimer, false);

			this.startIdleTimer();
		},
		startIdleTimer() {
			this.idleTimeout = window.setTimeout(this.handleInactivity, 30000);
		},
		resetIdleTimer() {
			this.clearIdleTimer();
			this.initIdleTimer();
		},
		clearIdleTimer() {
			document.removeEventListener(
				'mousemove',
				this.resetIdleTimer,
				false,
			);
			document.removeEventListener(
				'mousedown',
				this.resetIdleTimer,
				false,
			);
			document.removeEventListener(
				'keypress',
				this.resetIdleTimer,
				false,
			);
			document.removeEventListener(
				'touchmove',
				this.resetIdleTimer,
				false,
			);

			window.clearTimeout(this.idleTimeout);
		},
		async handleInactivity() {
			try {
				const { authenticated } = await this.$http.post('/auth/status');

				if (!authenticated) {
					window.location.href = '/';
				}
			} catch (err) {
				apiErrorHandler(err);
			}
		},
	},
};
