export const Accordion = () => import('../../src/components/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c))
export const AnchorLink = () => import('../../src/components/AnchorLink.vue' /* webpackChunkName: "components/anchor-link" */).then(c => wrapFunctional(c.default || c))
export const Flyout = () => import('../../src/components/Flyout.vue' /* webpackChunkName: "components/flyout" */).then(c => wrapFunctional(c.default || c))
export const FormBuilder = () => import('../../src/components/FormBuilder.vue' /* webpackChunkName: "components/form-builder" */).then(c => wrapFunctional(c.default || c))
export const Icon = () => import('../../src/components/Icon.vue' /* webpackChunkName: "components/icon" */).then(c => wrapFunctional(c.default || c))
export const List = () => import('../../src/components/List.vue' /* webpackChunkName: "components/list" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../src/components/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const ProgressBar = () => import('../../src/components/ProgressBar.vue' /* webpackChunkName: "components/progress-bar" */).then(c => wrapFunctional(c.default || c))
export const Spinner = () => import('../../src/components/Spinner.vue' /* webpackChunkName: "components/spinner" */).then(c => wrapFunctional(c.default || c))
export const Toast = () => import('../../src/components/Toast.vue' /* webpackChunkName: "components/toast" */).then(c => wrapFunctional(c.default || c))
export const AccountActivity = () => import('../../src/components/account/Activity.vue' /* webpackChunkName: "components/account-activity" */).then(c => wrapFunctional(c.default || c))
export const AnalyticsButton = () => import('../../src/components/analytics/Button.vue' /* webpackChunkName: "components/analytics-button" */).then(c => wrapFunctional(c.default || c))
export const AnalyticsEvent = () => import('../../src/components/analytics/Event.vue' /* webpackChunkName: "components/analytics-event" */).then(c => wrapFunctional(c.default || c))
export const AnalyticsLink = () => import('../../src/components/analytics/Link.vue' /* webpackChunkName: "components/analytics-link" */).then(c => wrapFunctional(c.default || c))
export const AnalyticsPageNav = () => import('../../src/components/analytics/PageNav.vue' /* webpackChunkName: "components/analytics-page-nav" */).then(c => wrapFunctional(c.default || c))
export const AuthCard = () => import('../../src/components/auth/Card.vue' /* webpackChunkName: "components/auth-card" */).then(c => wrapFunctional(c.default || c))
export const ChartBar = () => import('../../src/components/chart/Bar.vue' /* webpackChunkName: "components/chart-bar" */).then(c => wrapFunctional(c.default || c))
export const ChartDateFilter = () => import('../../src/components/chart/DateFilter.vue' /* webpackChunkName: "components/chart-date-filter" */).then(c => wrapFunctional(c.default || c))
export const ChartDonut = () => import('../../src/components/chart/Donut.vue' /* webpackChunkName: "components/chart-donut" */).then(c => wrapFunctional(c.default || c))
export const ChartFilterButton = () => import('../../src/components/chart/FilterButton.vue' /* webpackChunkName: "components/chart-filter-button" */).then(c => wrapFunctional(c.default || c))
export const ChartLine = () => import('../../src/components/chart/Line.vue' /* webpackChunkName: "components/chart-line" */).then(c => wrapFunctional(c.default || c))
export const ChartRow = () => import('../../src/components/chart/Row.vue' /* webpackChunkName: "components/chart-row" */).then(c => wrapFunctional(c.default || c))
export const ChartStat = () => import('../../src/components/chart/Stat.vue' /* webpackChunkName: "components/chart-stat" */).then(c => wrapFunctional(c.default || c))
export const ChartTable = () => import('../../src/components/chart/Table.vue' /* webpackChunkName: "components/chart-table" */).then(c => wrapFunctional(c.default || c))
export const ChromeHeader = () => import('../../src/components/chrome/Header.vue' /* webpackChunkName: "components/chrome-header" */).then(c => wrapFunctional(c.default || c))
export const ChromeHelpCenter = () => import('../../src/components/chrome/HelpCenter.vue' /* webpackChunkName: "components/chrome-help-center" */).then(c => wrapFunctional(c.default || c))
export const ChromeInfoBar = () => import('../../src/components/chrome/InfoBar.vue' /* webpackChunkName: "components/chrome-info-bar" */).then(c => wrapFunctional(c.default || c))
export const ChromeSidebar = () => import('../../src/components/chrome/Sidebar.vue' /* webpackChunkName: "components/chrome-sidebar" */).then(c => wrapFunctional(c.default || c))
export const DashboardButton = () => import('../../src/components/dashboard/Button.vue' /* webpackChunkName: "components/dashboard-button" */).then(c => wrapFunctional(c.default || c))
export const DashboardWelcome = () => import('../../src/components/dashboard/Welcome.vue' /* webpackChunkName: "components/dashboard-welcome" */).then(c => wrapFunctional(c.default || c))
export const FormCheckbox = () => import('../../src/components/form/Checkbox.vue' /* webpackChunkName: "components/form-checkbox" */).then(c => wrapFunctional(c.default || c))
export const FormColorSelector = () => import('../../src/components/form/ColorSelector.vue' /* webpackChunkName: "components/form-color-selector" */).then(c => wrapFunctional(c.default || c))
export const FormGroup = () => import('../../src/components/form/Group.vue' /* webpackChunkName: "components/form-group" */).then(c => wrapFunctional(c.default || c))
export const FormImageUpload = () => import('../../src/components/form/ImageUpload.vue' /* webpackChunkName: "components/form-image-upload" */).then(c => wrapFunctional(c.default || c))
export const FormInput = () => import('../../src/components/form/Input.vue' /* webpackChunkName: "components/form-input" */).then(c => wrapFunctional(c.default || c))
export const FormPasswordMeter = () => import('../../src/components/form/PasswordMeter.vue' /* webpackChunkName: "components/form-password-meter" */).then(c => wrapFunctional(c.default || c))
export const FormPhoneNumber = () => import('../../src/components/form/PhoneNumber.vue' /* webpackChunkName: "components/form-phone-number" */).then(c => wrapFunctional(c.default || c))
export const FormSelect = () => import('../../src/components/form/Select.vue' /* webpackChunkName: "components/form-select" */).then(c => wrapFunctional(c.default || c))
export const FormTextarea = () => import('../../src/components/form/Textarea.vue' /* webpackChunkName: "components/form-textarea" */).then(c => wrapFunctional(c.default || c))
export const FormToggle = () => import('../../src/components/form/Toggle.vue' /* webpackChunkName: "components/form-toggle" */).then(c => wrapFunctional(c.default || c))
export const ModalSidePanel = () => import('../../src/components/modal/SidePanel.vue' /* webpackChunkName: "components/modal-side-panel" */).then(c => wrapFunctional(c.default || c))
export const NavButton = () => import('../../src/components/nav/Button.vue' /* webpackChunkName: "components/nav-button" */).then(c => wrapFunctional(c.default || c))
export const NavMenuButton = () => import('../../src/components/nav/MenuButton.vue' /* webpackChunkName: "components/nav-menu-button" */).then(c => wrapFunctional(c.default || c))
export const ProgressPanel = () => import('../../src/components/progress/Panel.vue' /* webpackChunkName: "components/progress-panel" */).then(c => wrapFunctional(c.default || c))
export const SupportHeader = () => import('../../src/components/support/Header.vue' /* webpackChunkName: "components/support-header" */).then(c => wrapFunctional(c.default || c))
export const ThemeContentPanel = () => import('../../src/components/theme/ContentPanel.vue' /* webpackChunkName: "components/theme-content-panel" */).then(c => wrapFunctional(c.default || c))
export const ThemeDesignPanel = () => import('../../src/components/theme/DesignPanel.vue' /* webpackChunkName: "components/theme-design-panel" */).then(c => wrapFunctional(c.default || c))
export const ThemeHeader = () => import('../../src/components/theme/Header.vue' /* webpackChunkName: "components/theme-header" */).then(c => wrapFunctional(c.default || c))
export const TourApp = () => import('../../src/components/tour/App.vue' /* webpackChunkName: "components/tour-app" */).then(c => wrapFunctional(c.default || c))
export const ChromeMenuPanel = () => import('../../src/components/chrome/menu/Panel.vue' /* webpackChunkName: "components/chrome-menu-panel" */).then(c => wrapFunctional(c.default || c))
export const ChromeMenuUser = () => import('../../src/components/chrome/menu/User.vue' /* webpackChunkName: "components/chrome-menu-user" */).then(c => wrapFunctional(c.default || c))
export const ChromeMenuUserProfileButton = () => import('../../src/components/chrome/menu/UserProfileButton.vue' /* webpackChunkName: "components/chrome-menu-user-profile-button" */).then(c => wrapFunctional(c.default || c))
export const ThemeLinkDivider = () => import('../../src/components/theme/link/Divider.vue' /* webpackChunkName: "components/theme-link-divider" */).then(c => wrapFunctional(c.default || c))
export const ThemeLinkPreviewDivider = () => import('../../src/components/theme/link/PreviewDivider.vue' /* webpackChunkName: "components/theme-link-preview-divider" */).then(c => wrapFunctional(c.default || c))
export const ThemeLinkPreviewLink = () => import('../../src/components/theme/link/PreviewLink.vue' /* webpackChunkName: "components/theme-link-preview-link" */).then(c => wrapFunctional(c.default || c))
export const ThemeLinkRow = () => import('../../src/components/theme/link/Row.vue' /* webpackChunkName: "components/theme-link-row" */).then(c => wrapFunctional(c.default || c))
export const ThemeModuleRow = () => import('../../src/components/theme/module/Row.vue' /* webpackChunkName: "components/theme-module-row" */).then(c => wrapFunctional(c.default || c))
export const ThemePlatformRow = () => import('../../src/components/theme/platform/Row.vue' /* webpackChunkName: "components/theme-platform-row" */).then(c => wrapFunctional(c.default || c))
export const ThemePodcastFeedSettings = () => import('../../src/components/theme/podcast/FeedSettings.vue' /* webpackChunkName: "components/theme-podcast-feed-settings" */).then(c => wrapFunctional(c.default || c))
export const ThemePodcastPlatforms = () => import('../../src/components/theme/podcast/Platforms.vue' /* webpackChunkName: "components/theme-podcast-platforms" */).then(c => wrapFunctional(c.default || c))
export const ThemeSidebarAbout = () => import('../../src/components/theme/sidebar/About.vue' /* webpackChunkName: "components/theme-sidebar-about" */).then(c => wrapFunctional(c.default || c))
export const ThemeSidebarLinks = () => import('../../src/components/theme/sidebar/Links.vue' /* webpackChunkName: "components/theme-sidebar-links" */).then(c => wrapFunctional(c.default || c))
export const ThemeSidebarLinksContent = () => import('../../src/components/theme/sidebar/LinksContent.vue' /* webpackChunkName: "components/theme-sidebar-links-content" */).then(c => wrapFunctional(c.default || c))
export const ThemeSidebarMedia = () => import('../../src/components/theme/sidebar/Media.vue' /* webpackChunkName: "components/theme-sidebar-media" */).then(c => wrapFunctional(c.default || c))
export const ThemeSidebarMediaContent = () => import('../../src/components/theme/sidebar/MediaContent.vue' /* webpackChunkName: "components/theme-sidebar-media-content" */).then(c => wrapFunctional(c.default || c))
export const ThemeSidebarPlatforms = () => import('../../src/components/theme/sidebar/Platforms.vue' /* webpackChunkName: "components/theme-sidebar-platforms" */).then(c => wrapFunctional(c.default || c))
export const ThemeSidebarPlatformsContent = () => import('../../src/components/theme/sidebar/PlatformsContent.vue' /* webpackChunkName: "components/theme-sidebar-platforms-content" */).then(c => wrapFunctional(c.default || c))
export const ThemeSidebarPodcasts = () => import('../../src/components/theme/sidebar/Podcasts.vue' /* webpackChunkName: "components/theme-sidebar-podcasts" */).then(c => wrapFunctional(c.default || c))
export const ThemeSidebarPodcastsContent = () => import('../../src/components/theme/sidebar/PodcastsContent.vue' /* webpackChunkName: "components/theme-sidebar-podcasts-content" */).then(c => wrapFunctional(c.default || c))
export const ThemeSidebarProfile = () => import('../../src/components/theme/sidebar/Profile.vue' /* webpackChunkName: "components/theme-sidebar-profile" */).then(c => wrapFunctional(c.default || c))
export const ThemeSidebarSettings = () => import('../../src/components/theme/sidebar/Settings.vue' /* webpackChunkName: "components/theme-sidebar-settings" */).then(c => wrapFunctional(c.default || c))
export const ThemeSidebarSettingsContent = () => import('../../src/components/theme/sidebar/SettingsContent.vue' /* webpackChunkName: "components/theme-sidebar-settings-content" */).then(c => wrapFunctional(c.default || c))
export const ThemeSidebarStore = () => import('../../src/components/theme/sidebar/Store.vue' /* webpackChunkName: "components/theme-sidebar-store" */).then(c => wrapFunctional(c.default || c))
export const ThemeSidebarSubscribe = () => import('../../src/components/theme/sidebar/Subscribe.vue' /* webpackChunkName: "components/theme-sidebar-subscribe" */).then(c => wrapFunctional(c.default || c))
export const ThemeSidebarSubscribeContent = () => import('../../src/components/theme/sidebar/SubscribeContent.vue' /* webpackChunkName: "components/theme-sidebar-subscribe-content" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
