import { render, staticRenderFns } from "./authenticated.vue?vue&type=template&id=181a054b&"
import script from "./authenticated.vue?vue&type=script&lang=js&"
export * from "./authenticated.vue?vue&type=script&lang=js&"
import style0 from "./authenticated.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules_dev/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ChromeHelpCenter: require('/vercel/path0/src/components/chrome/HelpCenter.vue').default,ChromeSidebar: require('/vercel/path0/src/components/chrome/Sidebar.vue').default,ChromeInfoBar: require('/vercel/path0/src/components/chrome/InfoBar.vue').default,ChromeHeader: require('/vercel/path0/src/components/chrome/Header.vue').default,Toast: require('/vercel/path0/src/components/Toast.vue').default,Spinner: require('/vercel/path0/src/components/Spinner.vue').default})
