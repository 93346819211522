//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
	name: 'Default',
	computed: {
		...mapGetters({
			showToast: 'toast/show',
			autoDismiss: 'toast/autoDismiss',
			toastMessage: 'toast/message',
			toastTheme: 'toast/theme',
			toastIsLoading: 'toast/loading',
		}),
	},
};
