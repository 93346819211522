const errorHandler = (e, exit = true) => {
	let error = {
		name: 'Error',
		error: 'Bad Request',
		message: 'An error occurred',
		statusCode: 500,
	};

	console.error(e);

	if (e?.response) {
		if (e?.response?.data) {
			const { status } = e.response;
			const { name, type, statusCode, message } = e.response.data;

			if (name && type && status) {
				error.name = name;
				error.error = name;
				error.message = type;
				error.statusCode = status;
			} else if (error && statusCode && message) {
				error.name = name;
				error.error = name;
				error.message = message;
				error.statusCode = statusCode;
			}
		} else {
			const { status, statusText } = e.response;
			error.error = statusText || error.error;
			error.message = data.message || error.message;
			error.statusCode = status || error.statusCode;
		}
	} else if ('request' in e) {
		error.error = `Error: ${e.error}`;
		error.statusCode = e.status || error.statusCode;
	} else {
		error.error = `Error: ${e.error}`;
		error.message = e.message || error.message;
		error.statusCode = e.statusCode || error.statusCode;
	}

	if (exit) {
		throw error;
	} else {
		return error;
	}
};

export default errorHandler;
