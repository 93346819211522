import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2ee6e501 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _8e765632 = () => interopDefault(import('../src/pages/_username/index.vue' /* webpackChunkName: "pages/_username/index" */))
const _2f097af6 = () => interopDefault(import('../src/pages/_username/account/index.vue' /* webpackChunkName: "pages/_username/account/index" */))
const _60fbcd5e = () => interopDefault(import('../src/pages/_username/analytics/index.vue' /* webpackChunkName: "pages/_username/analytics/index" */))
const _342c0279 = () => interopDefault(import('../src/pages/_username/design.vue' /* webpackChunkName: "pages/_username/design" */))
const _48ff12b0 = () => interopDefault(import('../src/pages/_username/progress.vue' /* webpackChunkName: "pages/_username/progress" */))
const _7ab40858 = () => interopDefault(import('../src/pages/_username/team.vue' /* webpackChunkName: "pages/_username/team" */))
const _66324e08 = () => interopDefault(import('../src/pages/_username/account/delete.vue' /* webpackChunkName: "pages/_username/account/delete" */))
const _6752648f = () => interopDefault(import('../src/pages/_username/analytics/clicks.vue' /* webpackChunkName: "pages/_username/analytics/clicks" */))
const _5f65da86 = () => interopDefault(import('../src/pages/_username/analytics/events.vue' /* webpackChunkName: "pages/_username/analytics/events" */))
const _315828c5 = () => interopDefault(import('../src/pages/_username/analytics/links.vue' /* webpackChunkName: "pages/_username/analytics/links" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/",
    component: _2ee6e501,
    name: "index"
  }, {
    path: "/:username",
    component: _8e765632,
    name: "username"
  }, {
    path: "/:username/account",
    component: _2f097af6,
    name: "username-account"
  }, {
    path: "/:username/analytics",
    component: _60fbcd5e,
    name: "username-analytics"
  }, {
    path: "/:username/design",
    component: _342c0279,
    name: "username-design"
  }, {
    path: "/:username/progress",
    component: _48ff12b0,
    name: "username-progress"
  }, {
    path: "/:username/team",
    component: _7ab40858,
    name: "username-team"
  }, {
    path: "/:username/account/delete",
    component: _66324e08,
    name: "username-account-delete"
  }, {
    path: "/:username/analytics/clicks",
    component: _6752648f,
    name: "username-analytics-clicks"
  }, {
    path: "/:username/analytics/events",
    component: _5f65da86,
    name: "username-analytics-events"
  }, {
    path: "/:username/analytics/links",
    component: _315828c5,
    name: "username-analytics-links"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
