import LogRocket from 'logrocket';

export default function ({ env, $gtm }) {
	if (process.client) {
		if (env.appEnv === 'production') {
			window.onload = () => {
				if ('requestIdleCallback' in window) {
					window.requestIdleCallback(
						() => {
							if ($gtm) $gtm.init('GTM-WC9585N');
							LogRocket.init('hjr3rj/prod-the-editor');
						},
						{ timeout: 5000 },
					);
				} else {
					if ($gtm) $gtm.init('GTM-WC9585N');
					LogRocket.init('hjr3rj/prod-the-editor');
				}
			};
		}
	}
}
