let uuidv4 = null;
let Numeral = null;

import('uuid').then(({ v4 }) => {
	uuidv4 = v4;
});

import('numeral').then((numeral) => {
	Numeral = numeral;
});

export const storageKey = '_kadence.EditorCache_';

export const getWinSize = () => {
	if (typeof window !== 'undefined') {
		const w = window;
		const d = document;
		const e = d.documentElement;
		const g = d.getElementsByTagName('body')[0];
		const x = w.innerWidth || e.clientWidth || g.clientWidth;
		const y = w.innerHeight || e.clientHeight || g.clientHeight;

		return { width: x, height: y };
	}

	return { width: 0, height: 0 };
};

export const isMobile = () => {
	if (typeof window !== 'undefined') {
		return window.matchMedia('(min-width: 0px) and (max-width: 639px)')
			.matches;
	}

	return true;
};

export const isTablet = () => {
	if (typeof window !== 'undefined') {
		return window.matchMedia('(min-width: 640px) and (max-width: 1024px)')
			.matches;
	}

	return true;
};

export const orientation = () => {
	if (typeof window !== 'undefined') {
		const winDim = getWinSize();
		const width = winDim.width;
		const height = winDim.height;

		return width > height ? 'landscape' : 'portrait';
	}

	return null;
};

export const genUid = () => {
	return uuidv4();
};

export const slugify = (str) => {
	str = str.replace(/^\s+|\s+$/g, ''); // trim
	str = str.toLowerCase();

	// remove accents, swap ñ for n, etc
	const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
	const to = 'aaaaeeeeiiiioooouuuunc------';

	for (let i = 0, l = from.length; i < l; i++) {
		str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
	}

	str = str
		.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
		.replace(/\s+/g, '-') // collapse whitespace and replace by -
		.replace(/-+/g, '-'); // collapse dashes

	return str;
};

export const formatNumber = (number) => {
	if (!isNaN(number)) {
		return number > 1000 ? Numeral(number).format('0.0a') : number;
	}

	return number;
};

export const copyObject = (obj) => {
	return JSON.parse(JSON.stringify(obj));
};

export const removeEmptyProps = (obj, whitelist = []) => {
	Object.keys(obj).forEach((k) => {
		if (!whitelist.includes(k)) {
			if (!obj[k] && obj[k] !== undefined) delete obj[k];
		}
	});
};

export const stripTags = (str) => {
	return typeof str === 'string' ? str.replace(/(<([^>]+)>)/gi, '') : str;
};

export const removeLineBreaks = (str) => {
	return typeof str === 'string' ? str.replace(/(\r\n|\n|\r)/gm, ' ') : str;
};

export const removeLinks = (str) => {
	return typeof str === 'string' ? str.replace(/http\S+/g, '') : str;
};

export const splitIntoWords = (str) => {
	if (typeof str === 'string') {
		if (str) return str.replace(/([a-z])([A-Z])/g, '$1 $2');
		return '';
	}

	return str;
};

export const deepFreeze = (o) => {
	Object.freeze(o);

	Object.getOwnPropertyNames(o).forEach(function (prop) {
		if (
			o.hasOwnProperty(prop) &&
			o[prop] !== null &&
			(typeof o[prop] === 'object' || typeof o[prop] === 'function') &&
			!Object.isFrozen(o[prop])
		) {
			deepFreeze(o[prop]);
		}
	});

	return o;
};

export const deepSeal = (o) => {
	Object.seal(o);

	Object.getOwnPropertyNames(o).forEach(function (prop) {
		if (
			o.hasOwnProperty(prop) &&
			o[prop] !== null &&
			(typeof o[prop] === 'object' || typeof o[prop] === 'function') &&
			!Object.isSealed(o[prop])
		) {
			deepSeal(o[prop]);
		}
	});

	return o;
};

export const blockBody = (bool) => {
	const bodyEl = document.querySelector('body');

	if (bool) {
		if (bodyEl) {
			bodyEl.style.top = `-${window.scrollY}px`;
			bodyEl.classList.add('overflow-hidden', 'h-screen');
		}
	} else {
		if (bodyEl) {
			bodyEl.classList.remove('overflow-hidden', 'h-screen');

			const scrollY = bodyEl.style.top;
			bodyEl.style.top = '';

			window.scrollTo(0, parseInt(scrollY || '0') * -1);
		}
	}
};

export const apiErrorHandler = (form, err) => {
	const list = [];

	if (err) {
		const { name, message, statusCode } = err;
		let messageStr = 'An error occurred';

		if (form) {
			const totalErrors = Array.isArray(message) ? message.length : 0;

			messageStr = totalErrors
				? `There were ${totalErrors} errors with your submission, please review the highlighted fields`
				: message;

			if (totalErrors) {
				if (typeof form === 'string') {
					form = document.querySelector(form);
				}

				const errors = message;
				const formInputs = form?.elements;

				errors.forEach((item, idx) => {
					if (formInputs) {
						if (idx in formInputs) {
							const field = item.property;
							const message = Object.keys(item.constraints)
								.map((k) =>
									item.constraints[k].replace(field, 'Value'),
								)
								.join();

							list.push({ field, message });
						}
					}
				});
			}
		}

		if (name === 'AuthError' && statusCode === 401) {
			if (typeof window !== 'undefined') window.location.href = '/';
		}

		if (statusCode === 500) {
			messageStr =
				'We are currently experiencing technical difficulties. Please contact technical support';
		}

		if (err) console.error(err);
		return { message: messageStr, list };
	} else {
		return { message: null, list };
	}
};

export const browserCache = {
	getAll() {
		if (typeof window !== 'undefined') {
			const data = window.localStorage.getItem(storageKey);

			if (data) return JSON.parse(data);

			return null;
		}

		return null;
	},
	get(key) {
		const data = browserCache.getAll();
		if (data) return data?.[key] ?? null;

		return null;
	},
	set(key, val) {
		if (typeof window !== 'undefined') {
			const data = browserCache.getAll() || {};

			if (data) {
				data[key] = val;
				window.localStorage.setItem(storageKey, JSON.stringify(data));
			}
		}

		return null;
	},
	remove(key) {
		if (typeof window !== 'undefined') {
			const data = browserCache.getAll();

			if (data) {
				if (key in data) {
					delete data[key];
					window.localStorage.setItem(
						storageKey,
						JSON.stringify(data),
					);
				}
			}
		}

		return null;
	},
};
